import $ from "jquery";
import "select2";

const Targeting = ["interests", "behaviors", "demographics"];

const Demographics = {
  life_events: "Life Events",
  industries: "Industries",
  income: "Income",
  family_statuses: "Family Statuses",
  user_device: "User Device",
  education_schools: "Education Schools",
  education_majors: "Education Majors",
  work_employers: "Work Employers",
  work_positions: "Work Positions",
};

const GoogleAudienceSignal = [
  "detailed-demographics",
  "life-events",
  "user-interests-affinity",
  "user-interests-in-market",
];

const iconByType = {
  address: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-purple-600" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>`,
  city: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-indigo-600" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                  </svg>`,
  region: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green-600" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
                  </svg>`,
  zip: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-blue-600" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>`,
  country: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 h-5 w-5 text-red-600">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                    </svg>`,
  neighborhood: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 h-5 w-5 text-yellow-600">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
</svg>
`
};

const typeToInput = {
  address: 'addresses',
  city: 'cities',
  region: 'regions',
  zip: 'zips',
  country: 'countries',
  neighborhood: 'neighborhoods',
}

$(function () {
  $(".inner_conversion_actions").hide();

  $("#remote_agency_conversion_action").change(function () {
    var selectedValue = $(this).val();
    $(".inner_conversion_actions").hide();
    $("#" + selectedValue).show();
  });

  $("#remote_agency_conversion_action").trigger("change");

  $(".copyButton").click(function () {
    var snippetText = $(this).data("snippet");
    navigator.clipboard.writeText(snippetText).then(
      function () {
        var originalText = $(this).text();
        $(this).text("Copied!");
        var button = $(this);
        setTimeout(function () {
          button.text(originalText);
        }, 2000);
      }.bind(this),
      function (err) {
        console.error("Could not copy text: ", err);
      },
    );
  });

  var selectedValue = $("#remote_agency_pixel_id").val();
  changeDropdownValue(selectedValue);

  $("#remote_agency_pixel_id").change(function () {
    var selectedValue = $(this).val();
    changeDropdownValue(selectedValue);
  });

  function changeDropdownValue(selectedValue) {
    if (selectedValue) {
      $("#remote_agency_pixel_id").val(selectedValue);

      var metaPixelCode = `
        <!-- Meta Pixel Code -->
        &lt;script&gt;
          !function(f,b,e,v,n,t,s) {
            if(f.fbq) return;
            n = f.fbq = function() {
              n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if(!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
          }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${selectedValue}');
          fbq('track', 'PageView');
        &lt;/script&gt;
        &lt;noscript&gt;&lt;img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=${selectedValue}&ev=PageView&noscript=1"
        /&gt;&lt;/noscript&gt;
        <!-- End Meta Pixel Code -->
      `;
      $(".pixel_id_code").html(metaPixelCode);
      $(".pixel_id_code").removeClass("hidden");
      $("#copyPixelCode").removeClass("hidden");
    } else {
      $(".pixel_id_code").addClass("hidden");
      $("#copyPixelCode").addClass("hidden");
    }
  }

  var hiddenInput = document.getElementById("fb_pixel_id_input");
  if (hiddenInput && hiddenInput.value) {
    changeDropdownValue(hiddenInput.value);
  }

  $("#copyPixelCode").click(function () {
    var copyText = $(".pixel_id_code").text();
    navigator.clipboard.writeText(copyText).then(
      function () {
        var originalText = $("#copyPixelCode").text();
        $("#copyPixelCode").text("Copied!");
        setTimeout(function () {
          $("#copyPixelCode").text(originalText);
        }, 2000);
      },
      function (err) {
        console.error("Could not copy text: ", err);
      },
    );
  });
  function checkMaximumFields(element, prefix) {
    if (element.length >= 5) {
      alert("Maximum number of " + prefix + " reached 5.");
      return false;
    }
    return true;
  }
  $(".add-another-call-to-action").on("click", function () {
    if (
      !checkMaximumFields(
        $(".col-span-3.sm\\:col-span-2.call-to-action"),
        "call to action",
      )
    ) {
      return;
    }
    const callToActionDiv = $(".col-span-3.sm\\:col-span-2.call-to-action");
    const lastCallToActionDiv = callToActionDiv.last();
    lastCallToActionDiv.find("select").select2("destroy");
    const clonedCallToActionDiv = lastCallToActionDiv.clone(true);
    clonedCallToActionDiv.find("select").removeClass("required-field");
    clonedCallToActionDiv.find("span").text("Call to action button text");
    let counter = callToActionDiv.length + 1;
    const newId = `template_call_to_action_button_text_${counter}`;
    const newName = `template[meta][dynamic_creative][call_to_action_button_texts][]`;
    clonedCallToActionDiv.find("label").attr("for", newId);
    clonedCallToActionDiv
      .find("select")
      .attr("id", newId)
      .attr("data-select2-id", newId)
      .attr("name", newName);
    lastCallToActionDiv.after(clonedCallToActionDiv);
    clonedCallToActionDiv.find("select").select2();
    lastCallToActionDiv.find("select").select2();
  });

  function reemitToChange(e) { e.target.dispatchEvent(new Event('change', e)) }

  let $select2 = $(".select2");
  $select2.select2();
  $select2.on('select2:select', reemitToChange)
  $select2.on('select2:unselect', reemitToChange)
  if ($select2.hasClass("select2-hidden-accessible")) {
    $select2.addClass("hidden");
  }

  let $specialAdCategories = $(".select-special-ad-categories");
  $specialAdCategories.select2();
  if ($specialAdCategories.hasClass("select2-hidden-accessible")) {
    $specialAdCategories.addClass("hidden");
  }

  $specialAdCategories.on("change.select2", function (e) {
    if ($specialAdCategories.select2("data").length > 0) {
      $("div.detailed-targeting").addClass("hidden");
      $("div.detailed-targeting input").attr("disabled", true);

      $("div.restricted-detailed-targeting").removeClass("hidden");
      $("div.restricted-detailed-targeting input").attr("disabled", false);
    } else {
      $("div.restricted-detailed-targeting").addClass("hidden");
      $("div.restricted-detailed-targeting input").attr("disabled", true);

      $("div.detailed-targeting").removeClass("hidden");
      $("div.detailed-targeting input").attr("disabled", false);
    }
  });

  $(".category-icon").select2({
    templateResult: formatIcons,
  });

  Targeting.forEach(initializeTargetingSelect);
  GoogleAudienceSignal.forEach(initializeGoogleAudienceSignalSelect);

  $(".category-icon").on("select2:select", function (option) {
    let selected = option.params.data;
    $("span > i", ".category-icon-field").attr("class", selected.id);
  });

  $(".page-event").on("select2:select", function (option) {
    let selected = option.params.data;
    let retention = $(".retention");

    if (selected.id == "page_liked") {
      retention.toggle();
    } else if (
      selected.id != "page_liked" &&
      retention.css("display", "none")
    ) {
      retention.toggle();
    }
  });

  $(".select-all-targeting").select2({
    minimumInputLength: 4,
    placeholder: "Type to search",
    ajax: {
      delay: 250,
      url: "/targetings",
      dataType: "json",
      data: function (params) {
        return {
          q: params.term, // search term
          type: "all",
        };
      },
      processResults: function (data, _params) {
        return {
          results: data,
        };
      },
    },
    templateResult: formatResult,
  });

  if ($(".select-all-targeting").hasClass("select2-hidden-accessible")) {
    $(".select-all-targeting").addClass("hidden");
  }

  $(".select-all-targeting").on("select2:select", function (option) {
    const targetingType = option.params.data.targeting_type.toLowerCase();

    formatResultSelection(
      option.params.data,
      targetingType,
      `.${targetingType}-panel`,
    );
    $(".select-all-targeting").val([]).trigger("change");
  });

  let $address = $(".select-address");

  $address.select2({
    minimumInputLength: 4,
    placeholder: "Type to search",
    ajax: {
      delay: 250,
      url: function (_params) {
        return $(this).data("url");
      },
      dataType: "json",
      data: function (params) {
        return {
          q: params.term, // search term
        };
      },
      processResults: function (data, _params) {
        return {
          results: data,
        };
      },
    },
  });

  $address.on('select2:select', reemitToChange)
  $address.on('select2:unselect', reemitToChange)

  let $locations = $(".select-locations");

  $locations.select2({
    minimumInputLength: 4,
    placeholder: "Type to search",
    ajax: {
      delay: 250,
      url: function (_params) {
        return $(this).data("url");
      },
      dataType: "json",
      data: function (params) {
        return {
          q: params.term, // search term
        };
      },
      processResults: function (data, _params) {
        return {
          results: data,
        };
      },
    },
  });

  $locations.on('select2:select', reemitToChange)
  $locations.on("select2:select", function (event) {
    const { type, id, name, country_code } = event.params.data;
    const $locationItem = $(event.currentTarget);

    const minRadius = $locationItem.data("min-radius");
    const maxRadius = $locationItem.data("max-radius");
    const defaultRadius = $locationItem.data("default-radius");

    const minRadiusInKm = $locationItem.data("min-radius-in-km");
    const maxRadiusInKm = $locationItem.data("max-radius-in-km");
    const defaultRadiusInKm = $locationItem.data("default-radius-in-km");

    const distanceUnit = document.querySelector('input[name="remote_campaign_builder_job[distance_unit]"]').value;

    let item = `
      <div class="location-item" data-template="${typeToInput[type]}">
        <input type="hidden" name="remote_campaign_builder_job[${typeToInput[type]}][][key]" value="${id}" />
        <input type="hidden" name="remote_campaign_builder_job[${typeToInput[type]}][][country_code]" value="${country_code}" />

        <div class="flex items-center p-3 bg-gray-50 dark:bg-gray-700 rounded-lg space-x-4">
          <div class="w-full flex items-center space-x-3">
            ${iconByType[type]}
            <span class="location-name text-sm text-gray-700 dark:text-gray-200">${name}</span>
          </div>

          <div class="radius-input flex items-center space-x-2 ${type === 'address' ? '' : 'invisible'}">
            <select ${type === 'address' ? '' : 'disabled'} name="remote_campaign_builder_job[${typeToInput[type]}][][radius]" ${distanceUnit === 'mile' ? '' : 'disabled'} class="radius-input-mile ${distanceUnit === 'mile' ? '' : 'hidden'} form-input w-24 rounded-md border-gray-300 dark:border-gray-600 dark:bg-gray-800 text-sm">
              ${Array.from({ length: maxRadius - minRadius + 1 }, (_, i) => minRadius + i).map(value =>
      `<option value="${value}" ${value === defaultRadius ? 'selected' : ''}>+${value} mi</option>`
    ).join('')}
            </select>

            <select ${type === 'address' ? '' : 'disabled'} name="remote_campaign_builder_job[${typeToInput[type]}][][radius]" ${distanceUnit === 'kilometer' ? '' : 'disabled'} class="radius-input-km ${distanceUnit === 'kilometer' ? '' : 'hidden'} form-input w-24 rounded-md border-gray-300 dark:border-gray-600 dark:bg-gray-800 text-sm">
              ${Array.from({ length: maxRadiusInKm - minRadiusInKm + 1 }, (_, i) => minRadiusInKm + i).map(value =>
      `<option value="${value}" ${value === defaultRadiusInKm ? 'selected' : ''}>+${value} km</option>`
    ).join('')}
            </select>

            ${type === 'address' ? `<input type="hidden" name="remote_campaign_builder_job[${typeToInput[type]}][][distance_unit]" value="${distanceUnit}" />` : ''}
          </div>

          <button type="button" class="remove-location text-gray-400 hover:text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
    `;

    $("div#selected-locations").append(item);
    $("div.location-empty-state").addClass("hidden");
    $("button.fb-submit-button").removeClass("is-disabled");
    $("button.fb-submit-button").removeAttr("disabled");

    $locations.val([]).trigger("change");

    bindRemoveLocation();
  });

  $locations.on('select2:unselect', reemitToChange)
  $locations.on("select2:unselect", function (event) {
    $(`div#${event.params.data.key}`).remove();
  });

  if ($locations.hasClass("select2-hidden-accessible")) {
    $locations.addClass("hidden");
  }

  let $location = $(".select-location");

  $location.select2({
    minimumInputLength: 4,
    placeholder: "Type to search",
    ajax: {
      delay: 250,
      url: function (_params) {
        return $(this).data("url");
      },
      dataType: "json",
      data: function (params) {
        return {
          q: params.term, // search term
        };
      },
      processResults: function (data, _params) {
        return {
          results: data,
        };
      },
    },
  });

  $location.on('select2:select', reemitToChange)
  $location.on("select2:select", function (event) {
    const type = $(event.currentTarget).data().type;

    let locationInfo = `<div class="hidden" id="${event.params.data.key}">
     <input type="hidden" name="remote_campaign_builder_job[${type}][][key]" value="${event.params.data.key}" />
     <input type="hidden" name="remote_campaign_builder_job[${type}][][country_code]" value="${event.params.data.country_code}" />
     </div>
    `;

    $(event.currentTarget).parents("div.location").append(locationInfo);
  });

  $location.on('select2:unselect', reemitToChange)
  $location.on("select2:unselect", function (event) {
    $(`div#${event.params.data.key}`).remove();
  });

  if ($(".select2-search__field").length > 0) {
    $(".select2-search__field").width("100%");
  }

  if ($location.hasClass("select2-hidden-accessible")) {
    $location.addClass("hidden");
  }

  let $locale = $(".select-locale");

  $locale.select2({
    minimumInputLength: 2,
    placeholder: "Type to search",
    ajax: {
      delay: 250,
      url: function (_params) {
        return $(this).data("url");
      },
      dataType: "json",
      data: function (params) {
        return {
          q: params.term, // search term
        };
      },
      processResults: function (data, _params) {
        return {
          results: data,
        };
      },
    },
  });

  $locale.on('select2:select', reemitToChange)
  $locale.on("select2:select", function (event) {
    let localeInfo = `
      <input type="hidden" name="template[targeting][locale][key]" value="${event.params.data.key}" />
      <input type="hidden" name="template[targeting][locale][name]" value="${event.params.data.name}" />
    `;

    $(event.currentTarget)
      .parents("div.locale")
      .find("div.selected")
      .html(localeInfo);
  });

  $locale.on('select2:unselect', reemitToChange)
  $locale.on("select2:unselect", function (event) {
    $(`div#${event.params.data.key}`).remove();
  });

  if ($(".select2-search__field").length > 0) {
    $(".select2-search__field").width("100%");
  }

  if ($locale.hasClass("select2-hidden-accessible")) {
    $locale.addClass("hidden");
  }

  bindRemove();
  bindRemoveLocation();
});

function formatResult(result) {
  if (result.loading) {
    return result.text;
  }

  let $container = $(
    "<div class='select2-result-interest clearfix'>" +
    "<div class='select2-result-interest__meta'>" +
    "<div class='select2-result-interest__path'></div>" +
    "<div class='select2-result-interest__name'></div>" +
    "<div class='select2-result-interest__description'></div>" +
    "<div class='select2-result-interest__statistics'>" +
    "<div class='select2-result-interest__audience'><i class='fa fa-eye'></i> </div>" +
    "</div>" +
    "</div>" +
    "</div>",
  );

  let path = [];

  let targetingType = result.subtype || result.targeting_type;

  path = path.concat(result.path || []);
  if (result.type === "Demographic") {
    path.unshift(Demographics[targetingType]);
  }

  path = path.join(" -> ");

  $container.find(".select2-result-interest__path").text(path);
  $container.find(".select2-result-interest__name").text(result.name);
  $container
    .find(".select2-result-interest__description")
    .text(result.description);

  $container
    .find(".select2-result-interest__audience")
    .append(result.audience_size + " audience");

  return $container;
}

function formatResultSelection(result, type, parentElm) {
  if (result.name === undefined) return;

  $(parentElm).removeClass("hidden");

  let targetingType = result.subtype || result.targeting_type;

  let path = result.path || [];
  path.push(result.name);
  if (result.type === "Demographic") {
    path.unshift(Demographics[targetingType]);
  }
  path = path.join(" -> ");

  let $selection =
    $(`<li class="targeting-item pl-3 pr-4 py-3 flex items-center justify-between text-sm">
        <div class="w-0 flex-1 flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <span class="ml-2 flex-1 w-0">${path}</span>
        </div>
        <div class="ml-4 flex-shrink-0">
          <a href="#" class="remove-targeting font-medium text-gray-400 dark:text-gray-800 hover:text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </a>
        </div>
        <input value="${path}" name="template[targeting][${type}][][path]" type="hidden">
        <input value="${result.id}" name="template[targeting][${type}][][id]" type="hidden">
        <input value="${result.name}" name="template[targeting][${type}][][name]" type="hidden">
        <input value="${targetingType}" name="template[targeting][${type}][][type]" type="hidden">
      </li>`);

  $(parentElm).append($selection);

  bindRemove();
}

function bindRemove() {
  $("a.remove-targeting").on("click", function () {
    $(this).parents(".targeting-item").remove();
  });
}

function bindRemoveLocation() {
  $("button.remove-location").on("click", function () {
    $(this).parents(".location-item").remove();

    if ($("div.location-item").length === 0) {
      $("div.location-empty-state").removeClass("hidden");
      $("button.fb-submit-button").addClass("is-disabled");
      $("button.fb-submit-button").attr("disabled", true);
    }
  });
}

function formatIcons(result) {
  if (result.loading) {
    return result.text;
  }

  let $container = $(
    "<div class='select2-result-interest clearfix'>" +
    "<div class='select2-result-category__icon'><i class='" +
    result.id +
    "'></i></div>" +
    "<div class='select2-result-interest__meta'>" +
    "<div class='select2-result-interest__name'></div>" +
    "</div>" +
    "</div>",
  );

  $container.find(".select2-result-interest__name").text(result.text);

  return $container;
}

function initializeTargetingSelect(type) {
  let $targeting = $(`.select-${type}`);

  if ($targeting.length > 0) {
    if (type === "interests") {
      $targeting.select2({
        placeholder: `Add ${type}`,
        ajax: {
          url: "/targetings",
          dataType: "json",
          data: function (params) {
            return {
              q: params.term, // search term
              type: type,
              fetch_type: this.data("fetchType"),
            };
          },
          processResults: function (data, params) {
            return {
              results: data,
            };
          },
        },
        templateResult: formatResult,
      });

      $targeting.addClass("hidden");
    } else {
      $targeting.select2({
        placeholder: `Add ${type}`,
        data: [],
      });

      if ($targeting.hasClass("select2-hidden-accessible")) {
        let currentData = $targeting.select2("data");
        if (currentData.length <= 1) {
          $.ajax({
            url: "/targetings",
            data: { type: type, fetch_type: $targeting.data("fetchType") },
            dataType: "json",
            success: function (data) {
              $targeting.select2().empty();
              $targeting.append("<option></option>");
              $targeting.select2({
                placeholder: `Add ${type}`,
                data: data,
                templateResult: formatResult,
              });

              $targeting.addClass("hidden");
            },
          });
        }
      }
    }

    $targeting.on("select2:select", function (option) {
      formatResultSelection(
        option.params.data,
        type,
        $(`.${type}-panel`).first(),
      );
      $targeting.val([]).trigger("change");
    });
  }
}

function bindRemoveGoogle() {
  $("a.remove-audience").on("click", function () {
    $(this).parents(".audience-item").remove();
  });
}

function formatGoogleResultSelection(result, type, parentElm) {
  if (result.name === undefined) return;

  $(parentElm).removeClass("hidden");

  let $selection =
    $(`<li class="audience-item pl-3 pr-4 py-3 flex items-center justify-between text-sm">
        <div class="w-0 flex-1 flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
          <span class="ml-2 flex-1 w-0">${result.name}</span>
        </div>
        <div class="ml-4 flex-shrink-0">
          <a href="#" class="remove-audience font-medium text-gray-400 dark:text-gray-800 hover:text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </a>
        </div>
        <input value="${result.id}" name="template[targeting][google_audience_signal][${type}][][id]" type="hidden">
        <input value="${result.name}" name="template[targeting][google_audience_signal][${type}][][name]" type="hidden">
        <input value="${result.type}" name="template[targeting][google_audience_signal][${type}][][type]" type="hidden">
      </li>`);

  $(parentElm).append($selection);

  bindRemoveGoogle();
}

function initializeGoogleAudienceSignalSelect(type) {
  let $audienceSignal = $(`.select-${type}`);
  let selectPlaceholder = `Add ${type.replace(/-/g, " ").replace(/(^|\s)\S/g, (match) => match.toUpperCase())}`;

  if ($audienceSignal.length > 0) {
    $audienceSignal.select2({
      placeholder: selectPlaceholder,
      data: [],
    });

    if ($audienceSignal.hasClass("select2-hidden-accessible")) {
      let currentData = $audienceSignal.select2("data");
      if (currentData.length <= 1) {
        $.ajax({
          url: "/audience_signals",
          data: { type: type, fetch_type: $audienceSignal.data("fetchType") },
          dataType: "json",
          success: function (data) {
            $audienceSignal.select2().empty();
            $audienceSignal.append("<option></option>");
            $audienceSignal.select2({
              placeholder: selectPlaceholder,
              data: data,
            });
            if (
              $("#template_attributes").val() != undefined &&
              $("#template_attributes").val() != ""
            ) {
              if (type == "user-interests-affinity")
                populateAffinityInterests(
                  JSON.parse($("#template_attributes").val()),
                );
              else if (type == "user-interests-in-market")
                populateMarketInterests(
                  JSON.parse($("#template_attributes").val()),
                );
            }
            $audienceSignal.addClass("hidden");
          },
        });
      }
    }
  }

  function populateAffinityInterests(template_attributes) {
    if (template_attributes["Affinity Audience"] != undefined) {
      var affinity_audience = sanitizeAudience(
        template_attributes["Affinity Audience"],
      );

      affinity_audience.forEach(function (item) {
        var matchingOptions = $(
          ".select-user-interests-affinity option:contains('" + item + "')",
        ).filter(function () {
          return $(this).text() === item;
        });
        if (matchingOptions.length > 0) {
          $(`.user-interests-affinity-mock-button`).attr(
            "data-id",
            matchingOptions.val(),
          );
          $(`.user-interests-affinity-mock-button`).attr(
            "data-name",
            matchingOptions.text(),
          );
          $(`.user-interests-affinity-mock-button`).click();
        }
      });
    }
  }

  function populateMarketInterests(template_attributes) {
    if (template_attributes["In Market Audience"] != undefined) {
      var market_audience = sanitizeAudience(
        template_attributes["In Market Audience"],
      );

      market_audience.forEach(function (item) {
        var matchingOptions = $(
          ".select-user-interests-in-market option:contains('" + item + "')",
        ).filter(function () {
          return $(this).text() === item;
        });
        if (matchingOptions.length > 0) {
          $(`.user-interests-in-market-mock-button`).attr(
            "data-id",
            matchingOptions.val(),
          );
          $(`.user-interests-in-market-mock-button`).attr(
            "data-name",
            matchingOptions.text(),
          );
          $(`.user-interests-in-market-mock-button`).click();
        }
      });
    }
  }

  bindRemoveGoogle();

  if (type == "user-interests-affinity" || type == "user-interests-in-market") {
    $(`.${type}-mock-button`).click(function () {
      let option_name = this.dataset.name;
      let option_id = this.dataset.id;
      let option_type = "userInterests";
      let option = { id: option_id, name: option_name, type: option_type };
      formatGoogleResultSelection(
        option,
        type.replace(/-/g, "_"),
        `.${type}-panel`,
      );
      $audienceSignal.val([]).trigger("change");
    });
  }

  $audienceSignal.on("select2:select", function (option) {
    formatGoogleResultSelection(
      option.params.data,
      type.replace(/-/g, "_"),
      `.${type}-panel`,
    );
    $audienceSignal.val([]).trigger("change");
  });
}
